import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "提示文字" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx._placeholder[0],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._placeholder[0]) = $event)),
          size: "default",
          placeholder: "开始日期提示"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_el_input, {
          modelValue: _ctx._placeholder[1],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx._placeholder[1]) = $event)),
          size: "default",
          placeholder: "结束日期提示"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "日期格式" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          modelValue: _ctx._value.format,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx._value.format) = $event)),
          size: "default"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_option, {
              value: "yyyy",
              label: "年"
            }),
            _createVNode(_component_el_option, {
              value: "yyyy-MM",
              label: "年-月"
            }),
            _createVNode(_component_el_option, {
              value: "yyyy-MM-dd",
              label: "年-月-日"
            }),
            _createVNode(_component_el_option, {
              value: "yyyy-MM-dd HH:mm",
              label: "年-月-日 时:分"
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "展示时长" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_switch, {
          modelValue: _ctx._value.showLength,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx._value.showLength) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}